import { GeneralInformationProps } from "@/src/utils/globalTypes";
import useGeneral from "@/src/utils/useGeneral";
import Head from "next/head";
import { useEffect, useState } from "react";
import { noneSrc } from "../image/Images";

export const defaultOgImg = "/images/og-image.png";

const Meta = ({
  title,
  description,
  image,
  type = "storage-hunt",
  keyword,
  metaTitle,
  metaDescription,
}: {
  title: string;
  description: string;
  image?: string;
  type?: string;
  keyword?: string;
  metaTitle?: string;
  metaDescription?: string;
}) => {
  const [information, setInformation] =
    useState<GeneralInformationProps | null>(null);
  const { information: info } = useGeneral();

  useEffect(() => {
    const dataInfo = localStorage.getItem("general-information") || "";
    if (dataInfo) {
      const getData = JSON.parse(dataInfo);
      setInformation(getData);
    } else {
      setInformation(info);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Head>
      <title>{title}</title>
      <meta
        name="description"
        content={
          metaDescription ||
          information?.attributes?.seo?.metaDescription ||
          description
        }
      />
      <meta
        property="og:title"
        content={metaTitle || information?.attributes?.seo?.metaTitle || title}
      />
      <meta
        property="og:description"
        content={
          metaDescription ||
          information?.attributes?.seo?.metaDescription ||
          description
        }
      />
      {keyword && <meta name="keywords" content={keyword} />}
      {image ? (
        <meta property="og:image" content={image} />
      ) : (
        <meta
          property="og:image"
          content={
            information?.attributes?.seo?.metaImage
              ? information?.attributes?.seo?.metaImage.data?.attributes.url
              : defaultOgImg
          }
        />
      )}

      <meta property="og:type" content={type} />
    </Head>
  );
};
export default Meta;
