import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import Button from "@/src/component/button/Button";
import { useEffect, useState } from "react";
import { useIsMounted, useMediaQuery } from "usehooks-ts";
import AuthNavbar from "./auth-navbar";

export type AuthPopoverProps = {
  className?: string;
  btnType?: "sign-in" | "sign-up";
  label?: "Login" | "Sign Up";
};

export default function AuthPopover({
  className,
  btnType,
  label,
}: AuthPopoverProps) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isMounted()) return;
    setLoading(false);
  }, [isMounted]);

  if (loading)
    return <span className="text-sm text-neutral-600 italic">Loading...</span>;

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <div className="xl:w-32 px-[1.875rem] lg:px-0 py-3">
            <Button
              type="button"
              size={isSmallDevice ? "md" : "lg"}
              className={cn("!rounded-full lg:!py-4", {
                "!bg-transparent border border-neutral-900 !text-neutral-900 hover:!bg-neutral-100":
                  btnType === "sign-in",
                "!bg-neutral-900": btnType === "sign-up",
              })}
            >
              {label}
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-80 rounded-2xl shadow-lv3", className)}
          sideOffset={isSmallDevice ? 0 : 24}
        >
          <div className="grid gap-3.5">
            <h4 className="text-base/none font-medium text-neutral-900">
              {label} as
            </h4>

            <div className="grid gap-3">
              <AuthNavbar
                type={["buyer", `${btnType}`]}
                data={{
                  title: "Storage Buyer",
                  subtitle: `${
                    btnType === "sign-in" ? label : "Register"
                  } as a storage buyer to find auctions`,
                }}
              />

              <AuthNavbar
                type={["seller", `${btnType}`]}
                data={{
                  title: "Facility Manager",
                  subtitle: `${
                    btnType === "sign-in" ? label : "Sign up"
                  } as Facility Manager and register your facility`,
                }}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
