import { cn } from "@/lib/utils";
import { House2, People } from "iconsax-react";
import Link from "next/link";
import { useEffect, useState } from "react";

export type AuthPopoverProps = {
  type: string[];
  data: {
    title: string;
    subtitle: string;
  };
};

export default function AuthNavbar({
  type = ["seller", "sign-in"],
  data,
}: AuthPopoverProps) {
  const [url, setUrl] = useState("#");

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      setUrl(process.env.NEXT_PUBLIC_STORE_GOOGLE_URL!);
      return;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setUrl(process.env.NEXT_PUBLIC_STORE_APPLE_URL!);
      return;
    }

    setUrl(process.env.NEXT_PUBLIC_QRCODE!);
  };

  useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  return (
    <>
      <Link
        href={
          type[0] === "buyer"
            ? url
            : `${process.env.NEXT_PUBLIC_FACILITY_URL!}/auth/${type[1]}`
        }
        target="_blank"
      >
        <div
          className={cn(
            "rounded-2xl border p-3 flex gap-3 hover:bg-neutral-50 duration-150",
            {
              "border-primary-100": type[0] === "buyer",
              "border-neutral-100": type[0] === "seller",
            }
          )}
        >
          <div className="min-w-[2.75rem]">
            <div
              className={cn(
                "w-11 h-11 rounded-full p-2.5 flex items-center justify-center text-white",
                {
                  "bg-primary-500": type[0] === "buyer",
                  "bg-neutral-900": type[0] === "seller",
                }
              )}
            >
              {type[0] === "seller" ? (
                <House2 size={24} />
              ) : (
                <People size={24} />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h6 className="text-base/none font-medium text-neutral-900">
              {data.title}
            </h6>
            <p className="text-neutral-500 text-xs">{data.subtitle}</p>
          </div>
        </div>
      </Link>
    </>
  );
}
