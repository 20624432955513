import { ReactNode, useEffect, useState } from "react";
import Navbar from "./partials/navbar";
import Footer from "./partials/footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import useGeneral from "../utils/useGeneral";
import {
  DeviceStrapiProps,
  FooterGroupProps,
  GeneralInformationProps,
  SocmedStrapiProps,
} from "../utils/globalTypes";
import { useRouter } from "next/router";

type Props = {
  children: ReactNode;
  noFooter?: boolean;
  className?: string;
  withBanner?: ReactNode;
  footerContentClassName?: string;
  noNavbar?: boolean;
};

const robot = process.env.NEXT_PUBLIC_ALLOW_ROBOT as string;

export default function DefaultLayout({
  children,
  noFooter,
  className,
  withBanner,
  footerContentClassName,
  noNavbar,
}: Props) {
  const url = useRouter();
  const [information, setInformation] =
    useState<GeneralInformationProps | null>(null);
  const [dataFooter, setDataFooter] = useState<FooterGroupProps[]>([]);
  const [dataNavbar, setDataNavbar] = useState<FooterGroupProps[]>([]);
  const [dataSosmed, setDataSosmed] = useState<SocmedStrapiProps[]>([]);
  const [dataDevice, setDataDevice] = useState<DeviceStrapiProps | null>(null);
  const { information: info, footer, navbar, device, socmed } = useGeneral();

  useEffect(() => {
    window.analytics.ready(function () {
      if (!url.pathname.includes("/pricing")) {
        window.analytics.page("viewed");
      }
    });

    window.Intercom("update", {
      hide_default_launcher: false,
    });

    const dataInfo = localStorage.getItem("general-information") || "";
    if (dataInfo) {
      const getData = JSON.parse(dataInfo);
      setInformation(getData);
    } else {
      setInformation(info);
    }

    const dataFoot = localStorage.getItem("footer-group") || "";
    if (dataFoot) {
      const getDataF = JSON.parse(dataFoot);
      setDataFooter(getDataF);
    } else {
      setDataFooter(footer);
    }

    const dataNav = localStorage.getItem("navbar-group") || "";
    if (dataNav) {
      const getDataN = JSON.parse(dataNav);
      setDataNavbar(getDataN);
    } else {
      setDataNavbar(navbar);
    }

    const dataSos = localStorage.getItem("sosmed-group") || "";
    if (dataSos) {
      const getDataSos = JSON.parse(dataSos);
      setDataSosmed(getDataSos);
    } else {
      setDataSosmed(socmed);
    }

    const dataDev = localStorage.getItem("device-group") || "";
    if (dataDev) {
      const getDataDev = JSON.parse(dataDev);
      setDataDevice(getDataDev);
    } else {
      setDataDevice(device);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={`/images/iconfav.png`} />
        <link rel="shortcut icon" href={`/images/iconfav.png`} />
        <link rel="canonical" href={process.env.NEXT_PUBLIC_MARKETING_URL} />
        <meta name="robots" content="all" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Storage Hunt",
              url: process.env.NEXT_PUBLIC_MARKETING_URL,
              logo: "https://storage.googleapis.com/public-storagehunt-sandbox/cms/color_9e9ceff27e/color_9e9ceff27e.png",
              sameAs: [
                "https://x.com/storagehunthq ",
                "https://www.facebook.com/storagehunthq",
              ],
            }),
          }}
          key="product-jsonld"
        />
      </Head>

      <div className={`w-full min-h-screen bg-white ${className}`}>
        {!noNavbar && (
          <Navbar
            banner={withBanner}
            information={information}
            navbar={dataNavbar}
            device={dataDevice}
          />
        )}
        <main className="overflow-hidden">{children}</main>
        {!noFooter && (
          <Footer
            information={information}
            footer={dataFooter}
            footerContentClassName={footerContentClassName}
            device={dataDevice}
            sosmed={dataSosmed}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
}
