import {
  CloseCircleOutlineIcon,
  MiniMenuIcon,
} from "@/src/component/icon/SvgIcon";
import { Logo } from "@/src/component/image/Images";
import ModalDownloadApp from "@/src/component/modal/ModalDownloadApp";
import {
  DeviceStrapiProps,
  FooterGroupProps,
  GeneralInformationProps,
} from "@/src/utils/globalTypes";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import AuthPopover from "./auth-popover";

type Props = {
  banner?: ReactNode;
  information: GeneralInformationProps | null;
  navbar: FooterGroupProps[];
  device: DeviceStrapiProps | null;
};

export default function Navbar({
  banner,
  information,
  navbar = [],
  device,
}: Props) {
  const router = useRouter();
  const [open, isOpen] = useState(false);
  const [active, setActive] = useState("");
  const [modal, setModal] = useState(false);

  const getMenuActive = () => {
    if (router.asPath !== "/") {
      setActive(router.asPath.slice(1));
    } else {
      setActive(router.asPath);
    }
  };

  const onClick = () => {
    setModal(true);
  };

  useEffect(() => {
    getMenuActive();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <>
      <nav className="sticky top-0 bg-white z-50">
        {banner}
        <div className="lg:container flex-wrap flex justify-between items-center py-3.5 lg:py-4 px-4.5 2xl:px-default w-full">
          <Link href={"/"}>
            <Logo
              className="w-auto h-11"
              alt={
                information?.attributes?.logo_color.data?.attributes
                  .alternativeText
              }
              src={information?.attributes?.logo_color.data?.attributes.url}
            />
          </Link>
          <div className="hidden lg:block">
            <ul className="flex flex-wrap lg:justify-start lg:items-start justify-center items-center text-regular-none text-neutral-500 py-3">
              {navbar.map((e, key) => (
                <li className="px-4" key={key}>
                  <Link
                    href={e.type === "WRAPPER" ? `/${e.path!}` : e.externalPath}
                    className={`cursor-pointer hover:text-primary-900 hover-anime ${
                      active === e.path ? "text-primary-900 font-bold" : ""
                    }`}
                    target={e.type === "WRAPPER" ? "_self" : "_blank"}
                  >
                    {e.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="lg:flex flex-row items-center hidden gap-3">
            <AuthPopover label="Login" btnType="sign-in" />

            <AuthPopover label="Sign Up" btnType="sign-up" />
          </div>
          <div
            className="lg:hidden cursor-pointer w-1/12 flex justify-center"
            onClick={() => isOpen(!open)}
          >
            {!open ? (
              <MiniMenuIcon width="24" height="24" />
            ) : (
              <CloseCircleOutlineIcon width="24" height="24" />
            )}
          </div>
        </div>

        {/* Responsive Navbar */}
        <div
          className={`${
            !open && "hidden"
          } w-full bg-white shadow-lg absolute left-0 lg:hidden pb-4`}
        >
          <ul className="flex flex-col text-sm font-normal pt-4 px-4.5">
            {navbar.map((e, key: number) => (
              <li key={key}>
                <Link
                  href={e.type === "WRAPPER" ? `/${e.path!}` : e.externalPath}
                  className={`block p-3 text-small-tight text-neutral-600 ${
                    active === e.path ? "text-primary-500 font-bold" : ""
                  }`}
                  target={e.type === "WRAPPER" ? "_self" : "_blank"}
                >
                  {e.title}
                </Link>
              </li>
            ))}

            {/* <li>
              <div className="p-0">
                <AuthPopover
                  label="Login"
                  className="rounded-none w-auto"
                  btnType="sign-in"
                />
              </div>
            </li>
            {!router.asPath.startsWith("/become-seller") && (
              <li>
                <div className="p-3">
                  <AuthPopover
                    label="Sign Up"
                    className="PopoverContent"
                    btnType="sign-up"
                  />
                </div>
              </li>
            )} */}
          </ul>

          <AuthPopover
            label="Login"
            className="PopoverContent rounded-none"
            btnType="sign-in"
          />

          <AuthPopover
            label="Sign Up"
            className="PopoverContent rounded-none"
            btnType="sign-up"
          />
        </div>
      </nav>

      {modal && (
        <ModalDownloadApp
          show={modal}
          onClose={() => {
            setModal(false);
          }}
        />
      )}
    </>
  );
}
