import { AppleBoldIcon, GpBoldIcon } from "@/src/component/icon/SvgIcon";
import Images, { Logo } from "@/src/component/image/Images";
import {
  DeviceStrapiProps,
  FooterGroupProps,
  GeneralInformationProps,
  SocmedStrapiProps,
} from "@/src/utils/globalTypes";
import Link from "next/link";

type Props = {
  information: GeneralInformationProps | null;
  footer: FooterGroupProps[];
  footerContentClassName?: string;
  device: DeviceStrapiProps | null;
  sosmed: SocmedStrapiProps[];
};

export default function Footer({
  information,
  footer = [],
  footerContentClassName,
  device,
  sosmed,
}: Props) {
  return (
    <footer className="lg:container lg:px-4.5 2xl:px-default mt-16 md:mt-32">
      <div
        className={`bg-neutral-100 rounded-[2.125rem] p-12 ${footerContentClassName}`}
      >
        <Logo
          className="w-auto h-11"
          alt={
            information?.attributes?.logo_dark.data?.attributes.alternativeText
          }
          src={information?.attributes?.logo_dark.data?.attributes.url}
        />
        <div className="mt-12">
          <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-8 lg:gap-0 lg:divide-x divide-neutral-300">
            {footer
              .filter((v) => !v.parent)
              .map((e, key) => (
                <div
                  key={key}
                  className={`flex flex-col gap-4 ${key > 0 && "lg:pl-8"}`}
                >
                  <div className="text-large-none font-bold text-neutral-900">
                    {e.title}
                  </div>
                  <div className={`grid ${key === 0 ? "gap-3" : "gap-3"}`}>
                    {footer
                      .filter((vc) => vc.parent && vc.parent.id === e.id)
                      .map((ec, index) => (
                        <Link
                          href={
                            ec.type === "WRAPPER"
                              ? `/${ec.path!}`
                              : ec.externalPath
                          }
                          key={index}
                          className="font-medium text-regular-none text-neutral-800 hover:text-primary-900 hover-anime"
                          target={ec.type === "WRAPPER" ? "_self" : "_blank"}
                        >
                          {ec.title}
                        </Link>
                      ))}
                  </div>
                </div>
              ))}

            <div className="lg:pl-8 flex flex-col gap-7 text-neutral-900">
              <div className="flex flex-col gap-4 ">
                <div className="text-large-none font-bold">Follow Us On</div>
                <div className="flex items-center space-x-4.5 ">
                  {sosmed.map((e, key) => (
                    <Link
                      key={key}
                      href={e.attributes.url}
                      target={"_blank"}
                      className="hover:text-primary-900 hover-anime"
                      title={e.attributes.name}
                    >
                      <Images
                        src={e.attributes.icon.data.attributes.url}
                        className="w-6 h-6"
                        alt={e.attributes.name}
                      />
                    </Link>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="text-large-none font-bold">Available At</div>
                <div className="flex items-center space-x-4.5 ">
                  <Link
                    href={device?.attributes?.ios_url || "#"}
                    target={"_blank"}
                    className="hover:text-primary-900 hover-anime"
                    title={device?.attributes?.ios_name}
                  >
                    <AppleBoldIcon width="24" height="24" />
                  </Link>
                  <Link
                    href={device?.attributes?.android_url || "#"}
                    target={"_blank"}
                    className="hover:text-primary-900 hover-anime"
                    title={device?.attributes?.android_name}
                  >
                    <GpBoldIcon width="24" height="24" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center pt-9 pb-6 md:px-0 px-4.5 md:py-7">
        <p className="text-small-normal md:text-small-none text-neutral-500 text-center">
          © 2023 {information?.attributes?.brand_name} |{" "}
          {information?.attributes?.brand_name} is property of{" "}
          {information?.attributes?.company_name}.
        </p>
      </div>
    </footer>
  );
}
